//import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
//需要转换的中文

/*import banner1 from '@/assets/banner/ar/banner1.png'
import banner2 from '@/assets/banner/ar/banner2.png'
import banner3 from '@/assets/banner/ar/banner3.png'
import banner4 from '@/assets/banner/ar/banner4.png'*/

import ccc from "@/assets/img/ccc.png";
import glp from "@/assets/img/glp.png";
import cosco from "@/assets/img/cosco.png";

import ccc2 from "@/assets/img/ccc2.png";
import glp2 from "@/assets/img/glp2.png";
import cosco2 from "@/assets/img/cosco2.png";

import logo_img1 from "@/assets/img/logo_img1.png";
import logo_img2 from "@/assets/img/logo_img2.png";
import logo_img3 from "@/assets/img/logo_img3.png";

import link_icon from "@/assets/img/link_icon.png";
import link_icon2 from "@/assets/img/link_icon2.png";
import link_icon3 from "@/assets/img/link_icon3.png";
import link_icon4 from "@/assets/img/link_icon4.png";

import link_bg from "@/assets/img/brandValue-right1-ar.png";
import link_bg2 from "@/assets/img/link_bg2.png";
import link_bg3 from "@/assets/img/brandValue-right2-ar.png";
import link_bg4 from "@/assets/img/link_bg4.png";

import funeng_bg1 from "@/assets/img/funeng_bg1.png";
import funeng_bg2 from "@/assets/img/funeng_bg2.png";
import funeng_bg3 from "@/assets/img/funeng_bg3.png";
//import funeng_bg4 from '@/assets/img/funeng_bg4.png'

import funeng_icon1 from "@/assets/img/funeng_icon1.png";
import funeng_icon2 from "@/assets/img/funeng_icon2.png";
import funeng_icon3 from "@/assets/img/funeng_icon3.png";
import funeng_icon4 from "@/assets/img/funeng_icon4.png";

import brandValueLeft from "@/assets/img/brandValue-left-ar.png";

const ar = {
  message: {
    userinfo:
      "تقدم Zmetaport السلع عالية الجودة المصنوعة في الصين لجميع أنحاء العالم",
    shareholder_background: "خلفية المساهمين",
    shareholder_advantages: "المزايا التي جلبها المساهمون",
    brand_value: "قيمة العلامة التجارية", //这里不对，等品牌价值阿拉伯文
    digital_empower: "الرقمنة والذكاء التمكين",
    background_description:
      "تم استثمار Zmetaport بشكل مشترك من قبل شركة تشجيانغ تشاينا كوموديتيز سيتي جروب المحدودة., شركة كوسكو للشحن والخدمات اللوجستية المحدودة. وتهدف إلى تزويد الشركات الصغيرة والمتوسطة الحجم بخدمات الأداء اللوجستي عبر الحدود التي تغطي العالم بأسره وضمان الشفافية طوال العملية من خلال دمج مزايا الموارد للمساهمين ، وتجميع مزودي الخدمات اللوجستية عالية الجودة عبر الحدود، وتمكين جميع الروابط في مسار سلسلة الخدمات اللوجستية عبر الحدود بطريقة رقمية ومكثفة.",
    middle_box: [
      {
        bg_url: logo_img1,
        url: cosco2,
        companyName: "COSCO Logistics",
        companyName2: "中远海运",
        companyDesc:
          "شركة كوسكو للشحن اللوجستي المحدودة ، هي منصة خدمات سلسلة التوريد اللوجستية العالمية المتكاملة مع مكانة رائدة في السوق ضمن مجموعة كوسكو شيبنج جروب الصينية ، لديها نظام شبكة خدمات لوجستية يغطي العالم بأسره. وتوفر Zmetaport طرق الوصول إلى كل مكان في العالم.",
      },
      {
        bg_url: logo_img2,
        url: ccc2,
        companyName: "CCC Group",
        companyName2: "义乌小商品",
        companyDesc:
          "تشارك شركة تشيجيانج تشاينا كومودتس جروب المحدودة، وهي شركة مدرجة مملوكة للدولة، حصريا في تشغيل مدينة السلع الصينية في ييوو، أكبر سوق للسلع الصغيرة في العالم. باعتبارها واحدة من الشركات الرائدة في مجال التجارة والتجارة في الصين ، فإنه يخلق قاعدة ل Zmetaport لجمع السلع عالية الجودة المصنوعة في الصين.",
      },
      {
        bg_url: logo_img3,
        url: glp2,
        companyName: "GLP",
        companyName2: "普洛斯集团",
        companyDesc:
          "شركة جلوبال لوجستيك بروبرتيس هي شركة عالمية رائدة في مجال الخدمات الصناعية وإدارة الاستثمار تركز على البنية التحتية الجديدة في سلسلة التوريد والبيانات الضخمة ومجالات الطاقة الجديدة، وتمتلك شركات تغطي العالم، مع نطاق إدارة أصول يزيد عن 120 مليار دولار أمريكي ومنطقة بنية تحتية أكثر من من 49 مليون متر مربع. إنها تبني ميزة التخزين المنتشرة في جميع أنحاء العالم لشركة Zmetaport.",
      },
    ],
    advantage_one_one: "السلع\n",
    advantage_one_two: "صنع في الصين",
    advantage_one_three:
      "لقد رسخت شركة شركة تشيجيانج تشاينا كومودتس جروب المحدودة اقدامها في سوق ييفو لسنوات عديدة، وقد اكتسبت سمعة تجارية جيدة. من خلال إنشاء آلية 800 منظم للسلع تم تشكيلها في سوق ييوو ، عززت الرابطة الطبيعية لحقوق السلع ومالكيها.",

    advantage_two_one: "مستودعات في\n",
    advantage_two_two: "مستودعات في جميع أنحاء العالم",
    advantage_two_three:
      "مستودعات في جميع أنحاء العالممع موارد التخزين المفيدة في جميع أنحاء العالم، فإن شركة جلوبال لوجستيك بروبرتيس هي أول منشئ ومروج للخدمات اللوجستية الذكية والنظام البيئي الصناعي ذي الصلة في السوق الصينية.",

    advantage_three_one: "الطرق\n",
    advantage_three_two: "لطرق والوصول العالمي",
    advantage_three_three:
      "الطرق والوصول العالمي طرق الوصول إلى كل مكان في العالمبصفتها أكبر مزود عالمي للخدمات اللوجستية لسلسلة التوريد في صناعة الخدمات اللوجستية لا تتمتع شركة كوسكو للشحن اللوجستي المحدودة بمزاياها الخاصة في الطرق والفضاء فقط بل تتمتع أيضًا بتعاون وثيق في الموارد مع العديد من شركات الطيران.",

    tabs: ["الربط", "التجميع", "التحسين", "التمكين"],

    brand_data: [
      {
        text: "الربط",
        icon_url: link_icon,
        text_en: "",
        description:
          "بشكل فعال ورقمي، وكسر حواجز المعلومات، ونسج شبكة لوجستية متكاملة من طرف إلى طرف لمشاركة المعلومات والوصول إليها في جميع الروابط وتوفيرها بشكل مرئي.",
        bg_url: link_bg,
      },
      {
        text: "التجميع",
        icon_url: link_icon2,
        text_en: "",
        description:
          "لحل مشكلة الطلبات الصغيرة والسلع المتنوعة في المؤسسات متناهية الصغر والصغيرة والمتوسطة وتمكينها من التمتع بخدمات أداء أكثر فعالية من حيث التكلفة.",
        bg_url: link_bg2,
      },
      {
        text: "التحسين",
        icon_url: link_icon3,
        text_en: "",
        description: brandValueLeft, //此处应该是一张图片
        bg_url: link_bg3,
      },
      {
        text: "التمكين",
        icon_url: link_icon4,
        text_en: "",
        description:
          "الصين من العالم. نأمل أن نتمكن من تمكين البيئة اللوجستية العابرة للحدود بشكل أفضل ، ومساعدة الشركات المتناهية الصغر والصغيرة والمتوسطة على الخروج ، وتعزيز تطوير الصناعة والارتقاء الصناعي. تحقيق انتشار السلع المصنوعة في ييوو ، الصين حول العالم",
        bg_url: link_bg4,
      },
    ],
    digital_empower_desc:
      "بالاعتماد على إنترنت الأشياء والحوسبة السحابية والذكاء الاصطناعي والبيانات الضخمة والأدوات الأخرى ، تمكن Zmetaport بشكل مكثف ورقمي سلسلة كاملة من الخدمات اللوجستية عبر الحدود ، وتوفر خدمات أداء لوجستية شاملة عبر الحدود تغطي العالم بأسره وتضمن الشفافية في جميع أنحاء العالم. عملية للتجارة الإلكترونية عبر الحدود والشركات متناهية الصغر والصغيرة والمتوسطة من خلال تمكين جميع الروابط في مسار سلسلة الخدمات اللوجستية عبر الحدود بطريقة رقمية ومكثفة",

    digital_data: [
      {
        url: funeng_bg1,
        name: "اكسياو كيباي",
      },
      {
        url: funeng_bg2,
        name: "Zmetaport",
      },
      {
        url: funeng_bg3,
        name: "زوهوباي",
      },
    ],
    enpower_data: [
      "منتجات سلسلة التوريد عبر الحدود للمؤسسات\nمتناهية الصغر والصغيرة والمتوسطة",
      "المنتجات اللوجستية عبر الحدود\nلمؤسسات التجارة الإلكترونية",
      "منتجات خطية\nمخصصة من البداية إلى النهاية",
    ],
    boat_data: [
      {
        url: funeng_icon1,
        name: "هانجكسيانباو",
      },
      {
        url: funeng_icon2,
        name: "حجز المقصورة",
      },
      {
        url: funeng_icon3,
        name: "معلومات النقل",
      },
      {
        url: funeng_icon4,
        name: "حجز المستودع",
      },
    ],
    boat_middle_data: [
      {
        name1: "جيوانتا",
        name2: "برج مراقبة سلسلة التوريد",
      },
      {
        name1: "هيكسوتاي",
        name2: "منصة وسطية لسلسلة التوريد",
      },
      {
        name1: "بيداكسين",
        name2: "منصة خدمات الموارد",
      },
    ],
    operation_platform: "منصة حوسبة المجموعة",

    boat_bottom_data: [
      "مسار لوجستي كامل الارتباط",
      "معلومات صورة العميل",
      "معلومات التجارة عبر الحدود",
      "جودة خدمة المورد",
      "تكامل الموارد ونشر المعلومات",
    ],

    bottom_desc:
      "لوصول إلى الخدمات اللوجستية الرقمية والذكية إلى أي مكان في العالم",

    address_info: [
      {
        city: "شنغهاي",
        address:
          "شنغهاي غرفة 502، المبنى ج ، مركز فنتونج ، رقم 333، طريق شوهونج، حي منهانج، شنغهاي، الصين.",
      },
      {
        city: "ييوو",
        address:
          "الطابق 35 من مبنى ناكفو بلازا 2 رقم 799 في الطريق الشمالي من مدينة يويو، مقاطعة تشجيانغ",
      },
      {
        city: "ونينغبو",
        address:
          "نينغبو الطابق 13 ، كوسكو اللوجستية بناء ، رقم 36 ، هوايشو الطريق ، منطقة جيانغبى ، مدينة نينغبو ، مقاطعة تشجيانغ ، الصين",
      },
    ],

    contact: "قم الاتصال:",
    address: "معلومات العنوان",
    contactUs: "تابعنا",
    companyName:
      "تشجيانغ  شىجييوانقانغ ‎  الدولية سلسلة التوريد والتكنولوجيا المحدودة",
  },
  //...zhLocale
};

export default ar;
