import zhLocale from "element-ui/lib/locale/lang/zh-CN"; //引入element语言包
//需要转换的中文

/*import banner1 from '@/assets/banner/cn/banner1.png'
import banner2 from '@/assets/banner/cn/banner2.png'
import banner3 from '@/assets/banner/cn/banner3.png'
import banner4 from '@/assets/banner/cn/banner4.png'*/

import ccc from "@/assets/img/ccc.png";
import glp from "@/assets/img/glp.png";
import cosco from "@/assets/img/cosco.png";

import ccc2 from "@/assets/img/ccc2.png";
import glp2 from "@/assets/img/glp2.png";
import cosco2 from "@/assets/img/cosco2.png";

import logo_img1 from "@/assets/img/logo_img1.png";
import logo_img2 from "@/assets/img/logo_img2.png";
import logo_img3 from "@/assets/img/logo_img3.png";

import link_icon from "@/assets/img/link_icon.png";
import link_icon2 from "@/assets/img/link_icon2.png";
import link_icon3 from "@/assets/img/link_icon3.png";
import link_icon4 from "@/assets/img/link_icon4.png";

import link_bg from "@/assets/img/brandValue-right1-cn.png";
import link_bg2 from "@/assets/img/link_bg2.png";
import link_bg3 from "@/assets/img/brandValue-right2-cn.png";
import link_bg4 from "@/assets/img/link_bg4.png";

import funeng_bg1 from "@/assets/img/funeng_bg1.png";
import funeng_bg2 from "@/assets/img/funeng_bg2.png";
import funeng_bg3 from "@/assets/img/funeng_bg3.png";
//import funeng_bg4 from '@/assets/img/funeng_bg4.png'

import funeng_icon1 from "@/assets/img/funeng_icon1.png";
import funeng_icon2 from "@/assets/img/funeng_icon2.png";
import funeng_icon3 from "@/assets/img/funeng_icon3.png";
import funeng_icon4 from "@/assets/img/funeng_icon4.png";

import brandValueLeft from "@/assets/img/brandValue-left-cn.png";

const cn = {
  message: {
    userinfo: "集合派，让中国制造离世界更近",
    shipmentTracking: "运单跟踪",
    orderManagement: "订单管理",
    airFreightParcel: '空运小包',
    client: "客户端",
    shareholder_background: "股东背景",
    shareholder_advantages: "股东优势",
    brand_value: "品牌价值",
    digital_empower: "数智赋能",
    background_description:
      "集合派由义乌小商品城、中远海运物流、普洛斯集团联合投资成立；整合股东方资源优势，聚合优质跨境物流服务提供商，以数字化和集约化赋能跨境物流全链路、各环节。为中小微企业提供全域覆盖、全流程透明的跨境物流履约服务。",
    middle_box: [
      {
        bg_url: logo_img1,
        url: cosco2,
        companyName: "中远海运物流有限公司",
        companyName2: "中远海运",
        companyDesc:
          "中国远洋海运集团旗下具有领先市场地位的全球综合物流供应链服务平台，拥有辐射全球的物流服务网络系统。为集合派提供通达全球的航线资源。",
      },
      {
        bg_url: logo_img2,
        url: ccc2,
        companyName: "浙江中国小商品城集团股份有限公司",
        companyName2: "义乌小商品",
        companyDesc:
          "国有控股上市企业，独家经营全球最大的小商品市场——义乌中国小商品城，是我国商贸领域头部企业之一，为集合派打造聚集中国制造的好货基础。",
      },
      {
        bg_url: logo_img3,
        url: glp2,
        companyName: "普洛斯集团",
        companyName2: "普洛斯集团",
        companyDesc:
          "全球领先的专注于供应链、大数据及新能源领域新型基础设施的产投公司。资产管理规模超过1,200亿美元，全球园区基础设施面积超过4900万㎡，为集合派搭建布局世界的仓储优势。",
      },
    ],
    advantage_one_one: "好货·",
    advantage_one_two: "中国制造",
    advantage_one_three:
      "义乌小商品城耕耘义乌市场多年，积累了优质商誉，通过对义乌市场形成的800家组货人的机制建立，形成了天然的货权，货主的连接。",

    advantage_two_one: "仓储·",
    advantage_two_two: "布局世界",
    advantage_two_three:
      "普洛斯拥有着遍布全球的优势仓储资源，是中国市场最早启动智慧物流及相关产业生态系统的打造者和促进者。",

    advantage_three_one: "航线·",
    advantage_three_two: "通达全球",
    advantage_three_three:
      "中远海运物流作为物流行业内顶级的全球供应链物流服务供应商，不仅自身具有优势航线，舱位的能力，更与众多航司有着紧密资源合作。",

    tabs: ["连接", "聚合", "优化", "赋能"],

    brand_data: [
      {
        text: "连接",
        icon_url: link_icon,
        text_en: "CONNECT",
        description:
          "将物流链路中各环节高效数字化连接，打破信息壁垒，编织端到端一体化物流网络，各环节信息共享、接入，全程全局可视透明。",
        bg_url: link_bg,
      },
      {
        text: "聚合",
        icon_url: link_icon2,
        text_en: "AGGREGATE",
        description:
          "服务与货的双向聚合。聚合中小微企业货量，实现货拼柜，柜拼线，不仅解决中小微企业单小货杂的问题，更可以让其享受到更多，更高性价比的履约服务。",
        bg_url: link_bg2,
      },
      {
        text: "优化",
        icon_url: link_icon3,
        text_en: "OPTIMIZE",
        description: brandValueLeft,
        bg_url: link_bg3,
      },
      {
        text: "赋能",
        icon_url: link_icon4,
        text_en: "EMPOWER",
        description:
          "是我们的愿景，我们希望可以更好地赋能跨境物流生态帮助中小微企业更好地出海，助力行业发展，产业升级让",
        bg_url: link_bg4,
      },
    ],
    digital_empower_desc:
      "集合派依托物联网、云计算AI与大数据等工具，集约化、数字化赋能跨境物流全链路，为跨境电商及中小微贸易企业提供全域覆盖、全程透明的端到端跨境物流履约服务。",

    digital_data: [
      {
        url: funeng_bg1,
        name: "销客派",
      },
      {
        url: funeng_bg2,
        name: "集合派",
      },
      {
        url: funeng_bg3,
        name: "组货派",
      },
    ],
    enpower_data: [
      "中小微企业\n跨境供应链产品",
      "跨境电商\n物流产品",
      "专线\n端到端产品",
    ],
    boat_data: [
      {
        url: funeng_icon1,
        name: "航线集",
      },
      {
        url: funeng_icon2,
        name: "订舱集",
      },
      {
        url: funeng_icon3,
        name: "货讯集",
      },
      {
        url: funeng_icon4,
        name: "云仓集",
      },
    ],
    boat_middle_data: [
      {
        name1: "集元塔",
        name2: "供应链控制塔",
      },
      {
        name1: "合序台",
        name2: "供应链中台",
      },
      {
        name1: "派达芯",
        name2: "资源服务平台",
      },
    ],
    operation_platform: "集合运算平台",

    boat_bottom_data: [
      "全链路物流轨迹",
      "客户画像信息",
      "跨境贸易信息",
      "供应商服务质量",
      "资源整合调配信息",
    ],

    bottom_desc: "数智物流·派达全球",

    address_info: [
      {
        city: "上海",
        address: "上海市闵行区苏虹路333号万通中心C座502室",
        address1: "上海市闵行区苏虹路333号",
        address2: "万通中心C座502室",
      },
      {
        city: "义乌",
        address: "浙江省义乌市城北路799号中福广场2号楼35楼",
        address1: "浙江省义乌市城北路799号",
        address2: "中福广场2号楼35楼",
      },
      {
        city: "宁波",
        address: "浙江省宁波市江北区槐树路36号中远物流大厦13楼",
        address1: "浙江省宁波市江北区槐树路",
        address2: "36号中远物流大厦13楼",
      },
    ],

    contact: "联系电话：",
    address: "地址信息",
    contactUs: "关注我们",
    companyName: "浙江智捷元港国际供应链科技有限公司",
  },
  ...zhLocale,
};

export default cn;
