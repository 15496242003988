import enLocale from "element-ui/lib/locale/lang/en"; //引入element语言包
//中文对应的英文

/*import banner1 from '@/assets/banner/en/banner1.png'
import banner2 from '@/assets/banner/en/banner2.png'
import banner3 from '@/assets/banner/en/banner3.png'
import banner4 from '@/assets/banner/en/banner4.png'*/

import ccc from "@/assets/img/ccc.png";
import glp from "@/assets/img/glp.png";
import cosco from "@/assets/img/cosco.png";

import ccc2 from "@/assets/img/ccc2.png";
import glp2 from "@/assets/img/glp2.png";
import cosco2 from "@/assets/img/cosco2.png";

import logo_img1 from "@/assets/img/logo_img1.png";
import logo_img2 from "@/assets/img/logo_img2.png";
import logo_img3 from "@/assets/img/logo_img3.png";

import link_icon from "@/assets/img/link_icon.png";
import link_icon2 from "@/assets/img/link_icon2.png";
import link_icon3 from "@/assets/img/link_icon3.png";
import link_icon4 from "@/assets/img/link_icon4.png";

import link_bg from "@/assets/img/brandValue-right1-en.png";
import link_bg2 from "@/assets/img/link_bg2.png";
import link_bg3 from "@/assets/img/brandValue-right2-en.png";
import link_bg4 from "@/assets/img/link_bg4.png";

import funeng_bg1 from "@/assets/img/funeng_bg1.png";
import funeng_bg2 from "@/assets/img/funeng_bg2.png";
import funeng_bg3 from "@/assets/img/funeng_bg3.png";
//import funeng_bg4 from '@/assets/img/funeng_bg4.png'

import funeng_icon1 from "@/assets/img/funeng_icon1.png";
import funeng_icon2 from "@/assets/img/funeng_icon2.png";
import funeng_icon3 from "@/assets/img/funeng_icon3.png";
import funeng_icon4 from "@/assets/img/funeng_icon4.png";

import brandValueLeft from "@/assets/img/brandValue-left-en.png";

const en = {
  message: {
    userinfo: "Zmetaport，bring“Made in China” closer to the World",
    shipmentTracking: "TRACK ORDER",
    orderManagement: "ORDER MANAGEMENT",
    airFreightParcel: "AIR FREIGHT PARCEL",
    client: "CLIENT",
    shareholder_background: "SHAREHOLDERS' BACKGROUND",
    shareholder_advantages: "SHAREHOLDERS' ADVANTAGES",
    brand_value: "BRAND VALUE",
    digital_empower: "DIGITAL EMPOWER",
    background_description1:
      "Zmetaport is jointly invested in by CCC group Cosco-logistics and GLP.",
    background_description2:
      "It integrates the resource advantages of shareholders，aggregates high-quality cross-border logistics service providers.",
    background_description:
      "Through intensification and digitalization, it provides cross-border logistics services with full coverage and full transparency for MSMEs.",
    middle_box: [
      {
        bg_url: logo_img1,
        url: cosco2,
        companyName: "COSCO Logistics",
        companyName2: "中远海运",
        companyDesc:
          "A leading global integrated logistics supply chain service platform of COSCO, with a global logistics service network system. Provide  global route resources for Zmetaport.",
      },
      {
        bg_url: logo_img2,
        url: ccc2,
        companyName: "CCC Group",
        companyName2: "义乌小商品",
        companyDesc:
          "A State-Controlledisted company that exclusively operates the world's largest small commodity wholesale market - Yiwu China Commodities City. One of the leading companies in the field of commerce and trade across China. Build a goods base for Zmetaport to gather good products “Made in China”.",
      },
      {
        bg_url: logo_img3,
        url: glp2,
        companyName: "GLP",
        companyName2: "普洛斯集团",
        companyDesc:
          "The world's leading industrial service and investment management company, which focuses on supply chain, big data and new energy infrastructure. With a global footprint, its asset management scale exceeds 120 billion US dollars, and has over 49 million square meters of park infrastructure. Provides a global warehousing advantage for Zmetaport.",
      },
    ],
    advantage_one_one: "Goods\n",
    advantage_one_two: "— Made in China",
    advantage_one_three:
      "CCC has cultivated Yiwu market for many years and has accumulated high quality goodwill. It has formed a natural connection between goods rights and consignors, through the establishment of the mechanism of 800 consignors in Yiwu market.",

    advantage_two_one: "Warehouse\n",
    advantage_two_two: "— Has a global presence of warehouses around the world",
    advantage_two_three:
      "GLP owns superior storage resources worldwide, and is the earliest builder and promoter of intelligent logistics and related industrial ecosystem in the Chinese market.",

    advantage_three_one: "Route\n",
    advantage_three_two: "— Access to the world",
    advantage_three_three:
      "COSCO, the top global supply chain logistics service provider in the logistics industry, not only has its own advantageous routes and capacity, but also has close resource cooperation with many logistics companies.",

    tabs: ["CONNECT", "AGGREGATE", "OPTIMIZE", "EMPOWER"],

    brand_data: [
      {
        text: "CONNECT",
        icon_url: link_icon,
        text_en: "",
        description:
          "Connect all the links in the logistics efficiently and digitally, break information barriers, weave end-to-end integrated logistics network. Share and access information in each link, and the whole process of global visibility and transparency.",
        bg_url: link_bg,
      },
      {
        text: "AGGREGATE",
        icon_url: link_icon2,
        text_en: "",
        description:
          "Aggregate the cargo volume of MSMEs to achieve the realization of cargo assembly and line assembly, it not only solves the problem of MSMEs, but also enables them to enjoy more and more cost-effective performance services.",
        bg_url: link_bg2,
      },
      {
        text: "OPTIMIZE",
        icon_url: link_icon3,
        text_en: "",
        description: brandValueLeft, //此处应该是一张图片
        bg_url: link_bg3,
      },
      {
        text: "EMPOWER",
        icon_url: link_icon4,
        text_en: "",
        //description_en1:"Bring",
        //description_en2:"“Made in China”",
        description:
          " closer to the world is our vision. We hope to better empower the cross-border logistics ecology, to help MSMEs go overseasPromote the development and industrial upgrading of the industrySupport China goods, Gathered in Yiwu, Send to World",
        bg_url: link_bg4,
      },
    ],
    digital_empower_desc:
      "Zmetaport relies on the Internet of Things, Cloud computing AI, big data and other digital tools, Zmetaport intensive and digitalized enabling cross-border logistics full links. Provide cross-border e-commerce and MSMEs with full coverage and transparent end-to-end cross-border logistics performance services.",

    digital_data: [
      {
        url: funeng_bg1,
        name: "Xiaoke π",
      },
      {
        url: funeng_bg2,
        name: "Jihe π",
      },
      {
        url: funeng_bg3,
        name: "Zuhuo π",
      },
    ],
    enpower_data: [
      "Cross-Border Supply Chain\nProduct For MSMEs",
      "Cross-border e-commerce\nlogistics products",
      "Dedicated line End to\nEnd products",
    ],
    boat_data: [
      {
        url: funeng_icon1,
        name: "Hangxian Ji",
      },
      {
        url: funeng_icon2,
        name: "Dingcang Ji",
      },
      {
        url: funeng_icon3,
        name: "Huoxun Ji",
      },
      {
        url: funeng_icon4,
        name: "Yuncang Ji",
      },
    ],
    boat_middle_data: [
      {
        name1: "Collect Elements Tower",
        name2: "Supply Chain Control Tower",
      },
      {
        name1: "In order Platform",
        name2: "Middle desk of supply chain",
      },
      {
        name1: "Delivery Core",
        name2: "Resource Service Platform",
      },
    ],
    operation_platform: "Ensemble operating platform",

    boat_bottom_data: [
      "Full link logistics Track",
      "Customer Portrait Information",
      "Cross-border Trade Information",
      "Supplier quality of Service",
      "Information about resource integration and deployment",
    ],

    bottom_desc: "Intelligent Digital Logistics · Deliver globally",

    address_info: [
      {
        city: "Shanghai",
        address:
          "Room 502, Block C, Wantong Center, No. 333 Suhong Road, \nMinhang District, Shanghai",
      },
      {
        city: "Yiwu",
        address:
          "35F, Building 2, Zhongfu Plaza, 799 Chengbei Road, Yiwu City, Zhejiang Province",
      },
      {
        city: "Ningbo",
        address:
          "13F, COSCO Logistics Building, No. 36, Huaishu Road, Jiangbei District, \nNingbo City, Zhejiang Province",
      },
    ],

    contact: "TEL:",
    address: "ADDRESS",
    contactUs: "FOLLOW US",
    companyName:
      "Zhejiang Zhijie Yuangang International Supply Chain Technology Co., Ltd",
  },
  ...enLocale,
};

export default en;
